import React from "react";
import './feed.css';
import { useCallback, useState, useEffect } from "react";
import  Field from "../../components/field/Field";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const Feed = ( { isLoggedIn, loginUser, onLogout } ) => {
  const [cities, setCities] = useState(["New York", "Paris", "Tokyo", "London", "Sydney"]);
  const [finderFormData, setFinderFormData] = useState({departure: "", arrival: "", route:"", estimatedDate: formatDate(new Date()) });
  const [posterFormData, setPosterFormData] = useState({departure: "", arrival: "", route:"", estimatedDate: formatDate(new Date()), totalSeats: "", fare: { amount: "500", currency: "PKR" } });
  const [feedDetails, setFeedDetails] = useState([]);
  const [finderError, setFinderError] = useState("");
  const [posterError, setPosterError] = useState("");
  const [isPosterMode, setIsPosterMode] = useState(false);
  const navigate = useNavigate();

  const getAllRides = useCallback (async () => {
    try {
      if (isPosterMode) {
        const response = await axios.get('/api/v1/ride/requests/');
  
        setFeedDetails(response.data.requests);
      } else {
        const response = await axios.get('/api/v1/ride/');
  
        setFeedDetails(response.data.rides);        
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Session expired, redirecting to login...');
        onLogout();
        navigate('/login'); // Redirect user to login
      } else if (error.response) {
        console.log(error.response.data.message);
        isPosterMode ? setPosterError(error.response.data.error) : setFinderError(error.response.data.error);
      } else {
        isPosterMode ? setPosterError(error.response.data.error) : setFinderError(error.response.data.error);
        setFeedDetails([]); 
      }
    }
  }, [isPosterMode, onLogout, navigate]);

  useEffect(() => {
    let intervalId;

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && !intervalId) {
        intervalId = setInterval(getAllRides, 30000); // Poll every 30 seconds when tab is active
      } else if (document.visibilityState !== 'visible' && intervalId) {
        clearInterval(intervalId); // Pause polling when tab is inactive
        intervalId = null;
      }
    };
  
    // Fetch immediately when `isPosterMode` changes or when component mounts
    getAllRides();

    if (document.visibilityState === 'visible') {
      intervalId = setInterval(getAllRides, 30000);
    }
    
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      clearInterval(intervalId);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [isPosterMode, getAllRides]);
  
  useEffect(()=> {
    const defaultCities = loginUser.country !== "Pakistan" 
      ? ["New York", "Paris", "Tokyo", "London", "Sydney"] 
      : ["Islamabad", "Rawalpindi", "Attock", "Kamra", "Lahore"];
      
    setCities(defaultCities);
    setFinderFormData((prevFormData) => ({ ...prevFormData, departure: defaultCities[0], arrival: defaultCities[1] }));
    setPosterFormData((prevFormData) => ({ ...prevFormData, departure: defaultCities[0], arrival: defaultCities[1], fare: { amount: loginUser.country !== "Pakistan" ? "15" : "500", currency: loginUser.country !== "Pakistan" ? "USD" : "PKR", } }));
  }, [loginUser]);
  
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn, navigate]);

  // Helper function to format the date
  function formatDate(date) {
    const pad = (num) => String(num).padStart(2, '0');
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours()+1)}:${pad(date.getMinutes())}`;
  }

  const handleFinderChange = (event) => {
    setFinderError("");

    const { name, value } = event.target;
    setFinderFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleFinderSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.get('/api/v1/ride/filterRide/', {
        params: {
          departure: finderFormData.departure,
          arrival: finderFormData.arrival,
          route: finderFormData.route,
          date: finderFormData.date,
        }
      });

      setFeedDetails(response.data.filteredRides);
      
    } catch (error) {
      console.log(error.response.status);
      if (error.response && error.response.status === 401) {
        console.log('Session expired, redirecting to login...');
        onLogout();
        navigate('/login'); // Redirect user to login
      } else if (error.response.status === 404 ) {
        setFinderError('⚠️ No ride found on this route.');      
      }
    }
  };

  const handleRequestSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post('/api/v1/ride/createRequest/', finderFormData);
     
      const newRequest = response.data.newRequest;

      setFeedDetails((prevRides) => ([
        ...prevRides, 
        newRequest, 
      ]));
      
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Session expired, redirecting to login...');
        onLogout();
        navigate('/login'); // Redirect user to login
      } else if (error.response.status === 404 ) {
        setFinderError(error.response.data.error);      
      }
    }
  };

  const handleClearFilter = async (event) => {
    event.preventDefault();

    setFinderFormData({departure: "", arrival: "", date: "" });
    setFinderError("");

    getAllRides();
  }

  const handleGetSingleRide = (rideId) => {
   console.log(`handleGetSingleRide triggered with rideId: ${rideId}`);
   
   navigate(`/ride/${rideId}`);
  };
  
  const handlePosterClick = () => {
    setIsPosterMode(true); // Set the state to true when the user clicks Sign Up
    setFinderError("");
  };

  const handleFinderClick = () => {
    setIsPosterMode(false); // Set the state to false when the user clicks Finder
    setPosterError("");
  };

  /****************** */
  const handlePosterChange = (event) => {
    setPosterError(''); // Reset error message

    const { name, value } = event.target;
    setPosterFormData((prevData) => {
      if (name === 'amount') {
        return {
          ...prevData,
          fare: {
            ...prevData.fare,
            amount: value ? parseInt(value) : ''
          }
        };
      } else {
        return {
          ...prevData,
          [name]: value
        };
      }
    });
  };

  const handlePosterSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(`/api/v1/ride/createRide`, posterFormData);
      
      const newRide = response.data.newRide;

      setFeedDetails((prevRides) => ([
        ...prevRides, 
        newRide, 
      ]));
      
      setPosterFormData({departure: "", arrival: "", route:"", estimatedDate: "", totalSeats: "", fare: { amount: "", currency: "" } });

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Session expired, redirecting to login...');
        onLogout();
        navigate('/login'); // Redirect user to login
      } else {
        setPosterError(error.response.data.error);
        console.log('An error occurred while posting the ride');
      }
    }
  };
  
  return (
    <div className="gpt3__feed section__padding">
      <div className="feed__form">

        <div className="login__form-content">
          <div className="login__form-content__heading">
            <div className={`login__form-content__heading-container ${isPosterMode ? 'shift-right' : ''}`}>
            
              <div className="login__form-content__heading-container__wrapper">
                <h1 className={`gradient__text ${isPosterMode ? 'hide' : ''}`}>Let's find a ride!</h1>
                <p className={`${isPosterMode ? 'hide' : ''}`}>Select one or more filters and press 'Find a Ride'</p>
              </div>
            
              <div className="login__form-content__heading-container__wrapper">
                <h1 className={`gradient__text ${isPosterMode ? '' : 'hide'}`}>Offer Ride!</h1>
                <p className={`${isPosterMode ? '' : 'hide'}`}>Fill in complete details and press 'Post Ride'</p>
              </div>
            
            </div>        
          </div>
          
          <div className="login__form-content__input">
            <div className="login__form-content__input-slide-controls">
              <input type="radio" name="slide" id="finder" checked={!isPosterMode} onChange={handleFinderClick} />
              <input type="radio" name="slide" id="poster" checked={isPosterMode} onChange={handlePosterClick} />
              <label htmlFor="finder" className={`slide`} onClick={handleFinderClick}> Ride Finder </label>
              <label htmlFor="poster" className={`slide`} onClick={handlePosterClick}> Ride Poster </label>
              <div className={`slider-tab ${isPosterMode ? 'shift-right-slider' : ''}`}></div>
            </div>

            <div className={`login__form-content__input-container ${isPosterMode ? 'shift-right' : ''}`}>
              {/* Finder Form */}
              <div className="login__form-content__input-container__wrapper">
                <form onSubmit={handleFinderSubmit} className={`${isPosterMode ? 'hide' : ''}`} >
                  <div>  
                    <label htmlFor="departure">
                      From
                      <select id="departure" name="departure" value={finderFormData.departure} onChange={handleFinderChange}>
                      {cities.map((city) => (
                        <option value={city}>{city}</option>
                      ))}
                      </select>
                    </label>                  
                    <label htmlFor="arrival">
                      To
                      <select id="arrival" name="arrival" value={finderFormData.arrival} onChange={handleFinderChange}>
                      {cities.map((city) => (
                        <option value={city}>{city}</option>
                      ))}
                      </select>
                    </label>
                  </div>
                  <label htmlFor="route">
                    Via
                    <input type="text" id="route" name="route" value={finderFormData.route} onChange={handleFinderChange} placeholder="Highway M-1"/>
                  </label>
                  <label htmlFor="estimatedDate">
                    ETD
                    <input type="datetime-local" id="date" name="estimatedDate" value={finderFormData.estimatedDate} onChange={handleFinderChange}/>
                  </label>

                  <button type="submit">Find</button>
                  <button type="button" onClick={handleRequestSubmit}>Request Ride</button>
                  <p onClick={handleClearFilter}><Link to="#">Reset filters</Link></p>
                </form>
              </div>            

              {/* Poster Form */}
              <div className="login__form-content__input-container__wrapper">
                <form onSubmit={handlePosterSubmit} className={`${isPosterMode ? '' : 'hide'}`} >
                  <div>                  
                    <label htmlFor="departure">
                      From
                      <select id="departure" name="departure" value={posterFormData.departure} onChange={handlePosterChange}>
                      {cities.map((city) => (
                        <option value={city}>{city}</option>
                      ))}
                      </select>
                    </label>
                    <label htmlFor="arrival">
                      To
                      <select id="arrival" name="arrival" value={posterFormData.arrival} onChange={handlePosterChange}>
                      {cities.map((city) => (
                        <option value={city}>{city}</option>
                      ))}
                      </select>
                    </label>
                  </div>
                  
                  <label htmlFor="route">
                    Via
                    <input type="text" id="route" name="route" value={posterFormData.route} onChange={handlePosterChange} placeholder="Highway M-1"/>
                  </label>
                  <label htmlFor="estimatedDate">
                    ETD
                    <input type="datetime-local" id="date" name="estimatedDate" value={posterFormData.estimatedDate} onChange={handlePosterChange}/>
                  </label>
                  
                  <div>
                    <label htmlFor="amount">
                      Fare ({posterFormData.fare.currency})
                      <input type="number" id="amount" name="amount" min={1} value={posterFormData.fare.amount} onChange={handlePosterChange} placeholder="500"/>
                    </label>
                    <label htmlFor="totalSeats">
                      Available Seats
                      <input type="number" id="totalSeats" name="totalSeats" min={1} max={6} value={posterFormData.totalSeats} onChange={handlePosterChange} placeholder="1"/>
                    </label>
                  </div>

                  {posterError && <p className="profile__form-content__input-error">{posterError}</p>}
                  <button type="submit">Offer Ride</button>
                  
                </form>
              </div>
              
            </div>
          </div>
        </div>
      </div>

      
      {(finderError !== "")
      ? <div className="feed__container"> 
          <h2 className="feed__container-error__heading">{finderError}</h2>
        </div>
      : <div className="feed__container">      
          <h2 className="gradient__text">{isPosterMode ? "Recent Requests" : "Recent Offers"}</h2>

          <div className="feed__container-content">
          {
            feedDetails.map((element, idx) => {
              const { _id, /*user,*/ arrival, departure, estimatedDate} = element;
              
              let date = new Date(estimatedDate);
              let dateTime = "Date";
              const oneDayInMs = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

              if (Math.abs(date - Date.now()) < oneDayInMs) {
                date = date.toLocaleTimeString(); // Within 24 hours, show time
                dateTime = "Time";
              } else {
                date = date.toLocaleDateString(); // More than 24 hours away, show date
              }
              
              return (
                <div onClick={() => handleGetSingleRide(_id)} className="feed__container-content__post">
                    <Field heading="From" text={departure}></Field>
                    <Field heading="To" text={arrival}></Field>
                    <Field heading={dateTime} text={date}></Field>
                    {/*<Link to={`/profile/${_id}`}><Field heading="Posted by" text={user.userName}></Field></Link>*/}
                  </div>
              );
            })
          }      
          </div>
        </div>
      }
    </div>
  );
}

export default Feed;