import React from "react";
import { useLocation } from 'react-router-dom';
import './message.css';

const Message = () => {
  const location = useLocation();
  const { /*heading,*/ text } = location.state || { heading: 'Oops! Error...', text: '' }; // Default values if undefined

  return (
    <div className="message__container section__padding gradient__bg" id="message">
      <div className="message__container-content">
        <h1 className="gradient__text">Oops! Error...</h1>
        <p>{text}</p>
      </div>
    </div>
  );
}

export default Message;