import React from "react";
import { useState, useEffect } from "react";
import './ride.css';
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faTrashCan, faPlay, faCircleCheck, faCircleXmark, faEye, faCheck, faXmark} from '@fortawesome/free-solid-svg-icons';


const Ride = ( { isLoggedIn, loginUser, onLogout } ) => {
  const { rideId } = useParams();
  const [rideDetails, setRideDetails] = useState(null);
  const [deleteInProcess, setDeleteInProcess] = useState(false); // Ride Deleting state
  const [loading, setLoading] = useState(true); // Loading state
  const navigate = useNavigate();
  
  const rideUrl = `/api/v1/ride/getOneRide/${rideId}`;

  useEffect(() => {
    const getSingleRide = async () => {
      try {
        console.log('Fetching ride data from:', rideUrl);
        const response = await axios.get(rideUrl);
        
          setRideDetails(response.data.rideWithPosterPassengerInfo);
        setLoading(false); // Data is loaded
      } catch (error) {
          console.log(`[FrontEnd] error in getSingleRide, ${error}`);
          if (error.response && error.response.status === 401) {
            console.log('Session expired, redirecting to login...');
            onLogout();
            navigate('/login'); // Redirect user to login
          } else if (error.response) {
            console.log(error.response.data.message);
            //setError(error.response.data.message);
          } else {
            console.log('⚠️ An error occurred while fetching rides');
            //setError('⚠️ An error occurred while fetching rides');
          }    
      }
    };

    getSingleRide();
  }, [rideUrl, onLogout, navigate]);

  if (!isLoggedIn) {
    navigate('/');
    return null;
  }

  // Show a loading indicator while data is being fetched
  if (loading) {
    return (
      <div className="ride__details section__padding gradient__bg" id="ride">
        <div className="ride__details-heading">
          <h1 className="gradient__text">Loading Ride Detail...</h1>
        </div>  
      </div>
    );
  }

  // Show an indicator while deleting in process
  if (deleteInProcess) {
    return (
      <div className="ride__details section__padding gradient__bg" id="ride">
        <div className="ride__details-heading">
          <h1 className="gradient__text">Deleting Ride...</h1>
        </div>  
      </div>
    );
  }
  
  console.log(rideDetails);  
  const { _id, arrival, departure, route, estimatedDate, isStarted, totalSeats, isOfferOrRequest, fare, likes, poster, passengerList } = rideDetails;
  
  const onBtnClickLikeRide = async (rideID) => {
    try {
      // Optimistically update likes directly in rideDetails
      setRideDetails((prevDetails) => ({
        ...prevDetails,
        likes: likes + 1,
      }));

      const response = await axios.post(`/api/v1/ride/likeRide/${rideID}?_method=PUT`);      
      console.log("likes: ", response.data.likes);
      
      // Update likes from the response
      setRideDetails((prevDetails) => ({
        ...prevDetails,
        likes: response.data.likes,
      }));
      setLoading(false); // Data is loaded

    } catch (error) {
      console.log(error);

      // Revert the optimistic update on error
      setRideDetails((prevDetails) => ({
        ...prevDetails,
        likes: likes - 1,
      }));

      if (error.response && error.response.status === 401) {
        console.log('Session expired, redirecting to login...');
        onLogout();
        navigate('/login'); // Redirect user to login
      }
    }
  };

  const handleBookRide = async (rideID) => {
    try {
      // Optimistically update passenger directly in rideDetails
      setRideDetails((prevDetails) => ({
        ...prevDetails,
        totalSeats: totalSeats - 1,
        passengerList: [
          ...passengerList, {
            userID: "Loading",
            name: "Loading...",
            ride: rideID,
          }
        ]
      }));

      const response = await axios.post(`/api/v1/ride/addPassenger/${rideID}`);
      
      // Update totalSeats from the response
      setRideDetails((prevDetails) => ({
        ...prevDetails,
        totalSeats: response.data.updatedTotalSeats,
        passengerList: [
          ...passengerList,
          response.data.newPassenger
        ]
      }));
      setLoading(false); // Data is loaded

    } catch (error) {
      console.log(error);

      // Revert the optimistic update on error
      setRideDetails((prevDetails) => ({
        ...prevDetails,
        totalSeats: totalSeats + 1,
        passengerList: passengerList.filter(
          (passenger) => passenger.userID !== "Loading" // Remove the optimistically added passenger
        ),
      }));

      if (error.response && error.response.status === 401) {
        console.log('Session expired, redirecting to login...');
        onLogout();
        navigate('/login'); // Redirect user to login
      }
    }
  };

  const handleCancelBooking = async (rideID, passengerID) => {
    try {
      // Optimistically update passenger directly in rideDetails
      setRideDetails((prevDetails) => ({
        ...prevDetails,
        totalSeats: totalSeats + 1,
        passengerList: passengerList.filter(
          (passenger) => passenger.userID !== passengerID // Remove optimistically specific passengerID
        ),
      }));

      const response = await axios.post(`/api/v1/ride/deletePassenger/${rideID}&${passengerID}?_method=PUT`);

      // Update totalSeats & PassengerList from the response
      setRideDetails((prevDetails) => ({
        ...prevDetails,
        totalSeats: response.data.updatedTotalSeats,
        passengerList: passengerList.filter(
          (passenger) => passenger.userID !== response.data.deletedPassengerID // Remove optimistically specific passengerID
        ),
      }));
      setLoading(false); // Data is loaded

    } catch (error) {
      console.log(error);

      // Revert the optimistic update on error
      setRideDetails((prevDetails) => ({
        ...prevDetails,
        totalSeats: totalSeats - 1,
        passengerList: passengerList,
      }));

      if (error.response && error.response.status === 401) {
        console.log('Session expired, redirecting to login...');
        onLogout();
        navigate('/login'); // Redirect user to login
      }
    }
  };

  const handleToggleAvailed = async (rideID, passengerID) => {
    try {
      // Optimistically update isRideAvailed directly in rideDetails
      setRideDetails((prevDetails) => ({
        ...prevDetails,
        passengerList: passengerList.map(
          (passenger) => passenger.userID === passengerID 
          ? {...passenger, isRideAvailed: !passenger.isRideAvailed} 
          : passenger
        )
      }));

      const response = await axios.post(`/api/v1/ride/toggleAvailed/${rideID}&${passengerID}?_method=PUT`);

      // Update isRideAvailed from the response
      setRideDetails((prevDetails) => ({
        ...prevDetails,
        passengerList: passengerList.map(
          (passenger) =>  passenger.userID === passengerID 
          ? {...passenger, isRideAvailed: response.data.updatedAvailedMark}
          : passenger
        )
      }));
      setLoading(false); // Data is loaded

    } catch (error) {
      console.log(error);

      // Revert the optimistic update on error
      setRideDetails((prevDetails) => ({
        ...prevDetails,
        passengerList: passengerList.map(
          (passenger) => passenger.userID === passengerID 
          ? {...passenger, isRideAvailed: !passenger.isRideAvailed} 
          : passenger
        )
      }));

      if (error.response && error.response.status === 401) {
        console.log('Session expired, redirecting to login...');
        onLogout();
        navigate('/login'); // Redirect user to login
      }
    }
  };

  const onBtnClickStartRide = async (rideID) => {
    try {
      // Optimistically update likes directly in rideDetails
      setRideDetails((prevDetails) => ({
        ...prevDetails,
        isStarted: true,
      }));

      const response = await axios.post(`/api/v1/ride/startRide/${rideID}?_method=PUT`);

      // Revert the optimistic update on error
      setRideDetails((prevDetails) => ({
        ...prevDetails,
        isStarted: response.data.isStarted,
      }));
      setLoading(false); // Data is loaded

    } catch (error) {
      console.log(error);

      // Revert the optimistic update on error
      setRideDetails((prevDetails) => ({
        ...prevDetails,
        isStarted: false,
      }));

      if (error.response && error.response.status === 401) {
        console.log('Session expired, redirecting to login...');
        onLogout();
        navigate('/login'); // Redirect user to login
      }
    }
  };

  const onBtnClickDeleteRide = async (rideID) => {
    try {
      setDeleteInProcess(true);

      await axios.post(`/api/v1/ride/deleteRide/${rideID}?_method=PUT`);

      setDeleteInProcess(false);
      navigate('/profile');
    } catch (error) {
      console.log(error);
      setDeleteInProcess(false);

      if (error.response && error.response.status === 401) {
        console.log('Session expired, redirecting to login...');
        onLogout();
        navigate('/login'); // Redirect user to login
      }
    }
  };
  
  return (
    <div className="ride__details section__padding gradient__bg" id="ride">
      <div className="ride__details-heading">
        <h1 className="gradient__text">{isOfferOrRequest ? "Ride Detail" : "Offer Detail"}</h1>
      </div>    
      
      <div className="ride__details-content">
        <img src={poster.avatar} alt="" href="/profile"/>
        <div className="ride__details-content__wrapper">
          <label htmlFor="departure">From<input type="text" name="departure" value={departure}/></label>
          <label htmlFor="arrival">To<input type="text" name="arrival" value={arrival}/></label>
        </div>
        <div className="ride__details-content__wrapper">
          <label htmlFor="route">Via<input type="text" name="route" value={route}/></label>
        </div>
        <div className="ride__details-content__wrapper">
          <label htmlFor="date">ETD<input type="text" name="date" value={new Date(estimatedDate).toLocaleString([], { day:'2-digit', month:'short', year:'2-digit', hour: '2-digit', minute: '2-digit'})}/></label>
        </div>
        <div className="ride__details-content__wrapper">
          {isOfferOrRequest && <label htmlFor="amount">Fare ({fare.currency})<input type="text" name="amount" value={fare.amount}/></label>}
          {isOfferOrRequest && <label htmlFor="seats">Seats<input type="text" name="seats" value={totalSeats} /></label>}
        </div>
        <div className="ride__details-content__wrapper">
          <label htmlFor="poster">Posted by<Link to={`/profile/${poster._id}`}><input type="text" name="poster" value={poster.userName}/></Link></label>
          {isOfferOrRequest && <label htmlFor="status">Status<input type="text" name="status" value={isStarted ? 'Not Available' : 'Available'}/></label>}
        </div>

        <div className="ride__details-content__all-btn">
          {isOfferOrRequest && (poster._id !== loginUser._id) && (
            <>
              <button onClick={() => onBtnClickLikeRide(_id)}>{likes}<FontAwesomeIcon icon={faHeart}/></button>

              {totalSeats > 0 && !passengerList.some(el => el.userID === loginUser._id) &&
                <button onClick={() => handleBookRide(_id)} class="book-btn"><FontAwesomeIcon icon={faCircleCheck}/> Book</button>
              }

              {passengerList.some(el => el.userID === loginUser._id) &&
                <button onClick={() => handleCancelBooking(_id, loginUser._id)} class="delete-btn"><FontAwesomeIcon icon={faCircleXmark}/> Unbook</button>
              }

              {(!isStarted && loginUser.role === 'admin') &&
                <button onClick={() => onBtnClickDeleteRide(_id)} class="delete-btn"><FontAwesomeIcon icon={faTrashCan}/> Delete</button>
              } 
            </>
          )}

          {poster._id === loginUser._id && (
            <>
              {isOfferOrRequest && <button className="">{likes}<FontAwesomeIcon icon={faHeart}/></button>}

              {!isStarted && (
                <>
                  {isOfferOrRequest && <button onClick={() => onBtnClickStartRide(_id)} class=""><FontAwesomeIcon icon={faPlay}/>Start</button>}
                  <button onClick={() => onBtnClickDeleteRide(_id)} class="delete-btn"><FontAwesomeIcon icon={faTrashCan}/> Delete</button>
                </>
              )}
            </>
          )}
        </div>
      </div>

      {isOfferOrRequest && 
        <>
          <div className="ride__details-passenger__heading">
            <h2 className="gradient__text">Passengers list:</h2>
          </div> 
          
          {passengerList.map((el, idx) => {
            return (
              <div className="ride__details-passenger__content">
                <Link to={`/profile/${el.userID}`}><p>{`${idx+1}.  ${el.name}`}</p></Link>              
                <div>
                  <Link to={`/profile/${el.userID}`}><FontAwesomeIcon icon={faEye}/></Link>
                  {poster._id === loginUser._id && (
                    <>
                      {!el.isRideAvailed && <Link><FontAwesomeIcon icon={faCheck} onClick={handleToggleAvailed}/></Link>}
                      {el.isRideAvailed && <Link><FontAwesomeIcon icon={faXmark} onClick={handleToggleAvailed}/></Link>}
                    </>
                  )}
                  
                </div>
              </div>
            );
          })}
        </>
      }
      
    </div>
  );
}

export default Ride;